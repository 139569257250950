import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from "./TTMtable.module.css"
const TtmTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(location.state?.data || []);

  const departments = [
    'Administrative and General',
    'DED',
    'F&B Expense',
    'F&B Revenue',
    'Franchise Fees',
    'IT',
    'Insurance',
    'Management',
    'Management Fees',
    'Non-Operating Expenses',
    'Other Expense',
    'Other Income',
    'Property Operations and Maintenance',
    'Property Taxes',
    'Rent',
    'Rent & Miscellaneous Expense',
    'Rooms Expense',
    'Rooms Revenue',
    'Sales and Marketing',
    'Utilities'];

  const handleValueChange = (index, newValue) => {
    const updatedData = [...data];
    updatedData[index].Values = newValue;
    setData(updatedData);
  };

  const handleDepartmentChange = (index, newDepartment) => {
    const updatedData = [...data];
    updatedData[index].Departments = newDepartment;
    setData(updatedData);
  };

  const goToUploadFiles = () => {
    navigate('/uploadFiles');
  };

  const handleProceed = async () => {
    try {
      const response = await fetch('/api/op_history', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        console.log('Data sent successfully');
        const responseData = await response.json();
        navigate('/operationalHistory', { state: { data: responseData } });
      } else {
        console.error('Failed to send data');
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <div className='flex justify-content-between align-items-center'>
        <div>
          <h1 className='h4 text-600 py-2'>Extracted Financial Statement</h1>
          <p className='h5 text-500'>Please verify the below table and click on Confirm to proceed.</p>
        </div>
        <div className="button-container">
          <Button label='Back' className="button outlineButton" onClick={goToUploadFiles} />
          <Button label='Proceed' className='button' onClick={handleProceed} />
        </div>
      </div>
      <div>
        <table className="table table-striped table-hover">
          <thead className={styles.thead}>
            <tr>
              <th>Index</th>
              <th>Values</th>
              <th>Departments</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item["Line_Items"]}</td>
                <td>
                  <input
                    className="form-control"
                    type="text"
                    value={item.Values}
                    onChange={(e) => handleValueChange(index, e.target.value)}
                  />
                </td>
                <td>
                  <select
                    value={item.Departments}
                    onChange={(e) => handleDepartmentChange(index, e.target.value)}
                    className="form-select"
                  >
                    {departments.map((dept, deptIndex) => (
                      <option key={deptIndex} value={dept}>
                        {dept}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TtmTable;
