import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './KeyStatsNotFound.css';
import { Button } from 'primereact/button';

const KeyStatsNotFound = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    roomCount: '',
    occupancy: '',
    adr: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.occupancy > 100) {
      alert('Occupancy cannot be more than 100');
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch('/api/key_stats_notFound', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        const responseData = await response.json();
        navigate('/TTMtable', { state: { data: responseData } });
      } else {
        console.error('Failed to submit key stats');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="key-stats-container">
      {isLoading && <div className="loading-message m-auto">Submitting... please wait.</div>}
      {!isLoading && (
        <><div><p>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 20"><path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"/><path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 .912-.252 1.02-.598l.088-.416c.07-.295.176-.352.469-.288l.082-.38-2.29-.287-.082-.38.45-.083c.294-.07.352-.176.288-.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 .912-.252 1.02-.598l.088-.416c.07-.295.176-.352.469-.288l.082-.38z"/><circle cx="8" cy="4.5" r="1"/></svg>&nbsp;
          We did not find Key statistics of the hotel(eg: Occupancy, ADR, room count) in the Financial File submitted. Please enter the following below.</p></div>
        <div><form onSubmit={handleSubmit} className="key-stats-form">
          <h1 className='h4 text-600 py-2'>Enter Key Stats</h1>
          <div className="form-group">
            <label htmlFor="roomCount">Room count</label>
            <input
              type="number"
              id="roomCount"
              name="roomCount"
              value={formData.roomCount}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="occupancy">Occupancy (Max 100)</label>
            <input
              type="number"
              id="occupancy"
              name="occupancy"
              value={formData.occupancy}
              onChange={handleInputChange}
              max="100"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="adr">ADR</label>
            <input
              type="number"
              id="adr"
              name="adr"
              value={formData.adr}
              onChange={handleInputChange}
              required
            />
          </div>
          <Button label='Submit' type="submit" className='button' />
        </form></div></>
        
      )}
    </div>
  );
};

export default KeyStatsNotFound;
